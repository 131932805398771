import * as React from "react"
import H1 from "../utils/H1";
import H2 from "../utils/H2";
import H3 from "../utils/H3"
import H5 from "../utils/H5";
import Button from "../utils/Button";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BannerSlider from "../components/BannerSlider";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Paragraph from "../utils/Paragraph";
import SetTile from "../components/common/SetTile";
import CompanyTile from "../components/CompanyTile";
// import { FaBeer } from "@react-icons/all-files/fa/FaBeer";

const data = [
    {
        "name": "ACCESS",
        "description": "In addition to our decades of deep experience of growing businesses, our portfolio spans various sectors and allows for cross-disciplinary synergies as companies evolve. By providing access to prudent capital, seasoned partners, and an extensive network of investors, thought-leaders and industry experts, FPI will be a guiding hand through a company’s lifecycle.",
        "image": "/images/exp.png"
    },
    {
        "name": "EXPERIENCE",
        "description": "The definition of experience is to have knowledge or practical wisdom gained from what one has observed, encountered, or undergone. Our partners have decades of industry experience. In addition, we currently have a significant portfolio of companies that we draw from along with a number of our successful exits.",
        "image": "/images/user-experience.png"
    },
    {
        "name": "EXPERTISE",
        "description": "Our partners have spent over a decade investing in and growing companies. Building on a legacy of success across an array of disciplines, our experienced team confidently navigates companies through rapid growth, Series A through F investments, pivots, and market and/or strategic exits. All the while growing strong, industrialized and scalable companies.",
        "image": "/images/experience.png"
    },
    {
        "name": "PASSION",
        "description": "We believe that opportunities are endless and our companies are exceptional. Day or night, no matter where on the globe, FPI will stand ready, willing and able to help and advise our companies and their management teams to reach their aspirations and potential.",
        "image": "/images/heart.png"
    },
];
const AboutUs = () => (
    <Layout>
        <Seo title="Our Story" />
        <div className="md:h-400px h-500px bg-contact lg:bg-center bg-center bg-fixed bg-no-repeat bg-cover relative">
            <div className="overlay absolute w-full h-full top-0 left-0 opacity-80 ">

            </div>
            <div className="absolute w-full h-full flex flex-col justify-center items-center">
                <H1 className="text-white bg-none text-center">Message from our founding partners</H1>
                <div className="flex mx-auto lg:w-8/12 md:w-10/12 w-11/12 items-end justify-start mt-8">
                    <img src="/images/gaurav.jpg" alt="Gaurav Arora" className="md:w-28 w-20 rounded-xl object-contain mt-4 mr-4" />
                    <div className="flex flex-col w-full">
                        <Paragraph className="text-white text-left mt-0">Flying Point Industries was founded on the principle that passion combined with expertise, experience, and access can help companies reach their full potential. Our venture firm evaluates early-stage to mid-stage companies, initially investing anywhere from $200k to $2MM.</Paragraph>
                        <Paragraph className="text-white text-left font-bold w-full -mt-2">- Gaurav Arora, Partner, Flying Point Industries</Paragraph>
                    </div>
                </div>
            </div>
        </div>
        <div className="px-5% py-20 bg-white w-full flex flex-col justify-center items-left">
            <div className="grid lg:grid-cols-2 lg:grid-rows-2 auto-rows-min grid-cols-1 lg:gap-12 gap-y-12">
                {data.map((row, index) => (
                    <div className="flex md:flex-row flex-col about-tile md:p-8 p-6" key={index}>
                        <div className="rounded-md w-20 h-20 flex items-center justify-center bg-blue md:mb-0 mb-8">
                            <img src={row.image} alt="" className="w-8/12" />
                        </div>
                        <div className="flex flex-col md:w-10/12 md:pl-4">
                            <H3 className="text-blue">{row.name}</H3>
                            <Paragraph className="text-grayText text-base">{row.description}</Paragraph>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </Layout>
)

export default AboutUs
