import React from 'react'
import Paragraph from '../utils/Paragraph'
import Button from '../utils/Button'
import H3 from '../utils/H3'
export default function CompanyTile(props) {
    return (
        <a href={props.data.portfolio.link} target="_blank" className="w-full flex justify-center items-center">
            <div className="p-5 flex flex-col cursor-pointer rounded-xl transition-all duration-300 ease-in-out relative overflow-hidden group">
                {/* <div className="flex flex-row justify-start items-start"> */}
                {/* <h3 className="text-blue font-bold mt-2 mb-4 md:max-w-80% md:text-2xl text-xl">{props.data.title}</h3> */}
                <img src={props.data.featuredImage.node.mediaItemUrl} className="ml-auto mb-5 md:h-32 w-auto filter group-hover:filter-none transform group-hover:scale-110 transition-all duration-300 ease-in-out object-contain"></img>
                {/* </div> */}

                {/* <div className={"md:w-10/12 text-base text-textGray"} dangerouslySetInnerHTML={{ __html: props.data.portfolio.description }}></div> */}
                {/* {
                    props.portfolio ?
                        <Button className="hidden text-sm ml-0 mr-auto bg-orange text-white button-shadow-orange md:mt-0 mt-8" type={"external"} portfolio={props.portfolio}>read more</Button>
                        : ""} */}
                {/* <img src="/images/download-1.png" className="client-bg w-52 filter grayscale group-hover:filter-none"></img> */}
            </div>
        </a>
    )
}
